import browserHydrateFunction from "./src/utilities/gatsby/browser-hydrate-function";

export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0);
  const body = document.getElementsByTagName("body")[0];
  body.scrollTop = 0;
  return false;
}

export const replaceHydrateFunction = browserHydrateFunction;
